// Write your custom style. Feel free to split your code to several files

.by-andrey {
    left: 0;
    position: fixed; 
    bottom: 0px;
    z-index: 9999;
    background-color: white;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 5px;
    padding-top: 5px;
    box-shadow: 6px -4px 63px -8px rgba(0,0,0,0.75);
    
    border-top: 1px solid lightgrey;
    border-right: 1px solid lightgrey;
    border-top-right-radius: 15px;
    color: #3a3a3a;
}

.by-andrey:hover {
    padding-bottom: 25px;
    color: #3a3a3a;
    box-shadow: 6px -4px 5px -8px rgba(0,0,0,1);
}

.by-img {
    width: 30px;
    height: 30px; 
    border-radius: 50%;
}

.section {
	padding-top: 3rem;
	padding-bottom: 3rem;
}

.makewebisite {
	
}

.btn-shadow {
	box-shadow: 2px 2px 2px 1px #645d5d;
}


.featured {
    display: inline;
    height: 75px;
    width: 160px;
    border-color: transparent;
    padding: 10px;
    transform: scale(1);
    transition: transform .3s ease-in-out;

    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
      filter: grayscale(100%);
}

.featured:hover{
    transform: scale(1.05);
    filter: none;
}

.padding-top {
	padding-top: 40px;
}

.text-green{
	color: #00a760;
}

.pricing-1 .plan-name {
	background-color: white;
}

.figure {
    -webkit-transition: .2s ease-in-out;
            transition: .2s ease-in-out;
}

.figure:hover {
    transform: scale(1.05);
}
// @media only screen and (max-width: 600px) {
//   .hide-on-mobile {
//   	display: none;
//   }
// }

// header {
// 	background: -webkit-linear-gradient(0deg, #0b8442 0%, #1dd159 100%);
// }

// nav {
// 	background-color: $green;
// }

